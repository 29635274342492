<template>
  <div>
    <a-modal v-model:visible="visible" title="查看处方" @ok="handleOk" okText="新建" cancelText="关闭" :afterClose="closeModal" width="50vw" :okButtonProps="{ style: { display: 'none' }}">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-form-item label="名称">
          {{data?.name}}
          <!-- <a-input v-model:value="detailData.name" @change="changeNameInitial()" /> -->
        </a-form-item>
        <a-form-item label="描述">
          {{data?.description}}
          <!-- <a-input v-model:value="detailData.description" /> -->
        </a-form-item>
        <a-form-item label="来源">
          {{data?.source}}
          <!-- <a-input v-model:value="detailData.source" /> -->
        </a-form-item>

        <div style="font-size: 20px;font-weight:bold;margin-bottom: 10px;">处方</div>
        <div class="tableStyle">
          <div class="tbody">
            <div class="tr">
              <div class="th" v-for="item in data.items">{{item.name}}</div>
            </div>
          </div>
        </div>
      </a-form>

    </a-modal>
  </div>
</template>

<script>
import { Detail } from "/src/api/prescription.js";
export default {
  data() {
    return {
      visible: this.modelValue,
      columns: [
        {
          title: "名称",
          key: "itemName",
          dataIndex: "itemName",
          width: "30%",
          slots: {
            customRender: "itemName"
          }
        },
        {
          title: "用量",
          key: "quantity",
          dataIndex: "quantity",
          // ellipsis: true,
          width: "20%",
          slots: {
            customRender: "quantity"
          }
        },
        {
          title: "名称",
          key: "itemName2",
          dataIndex: "itemName2",
          width: "30%",
          slots: {
            customRender: "itemName2"
          }
        },
        {
          title: "用量",
          key: "quantity2",
          dataIndex: "quantity2",
          // ellipsis: true,
          width: "20%",
          slots: {
            customRender: "quantity2"
          }
        },
      ],
      data: {
        name: undefined,
        description: undefined,
        source: undefined,
        quantity: undefined,
        items: [],
      },
      labelCol: {
        span: 1,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {

  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {}
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {

    async detail() {
      let res = await Detail(this.record.uuid);
      console.log(res.data.items);
      let data = []
      if(!res.data.items){
        return
      }
      res.data.items.forEach((element, i) => {
        data.push({
          name: element.itemName,
        })
        data.push({
          name: element.quantity,
        })
      });
      console.log();
      this.data = { ...res.data, items: data };
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    }


  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.tableStyle {
  border: 1px solid #f0f0f0;
  padding: 10px;
}
.thead > .tr {
  background-color: #fafafa;
}
.tr {
  display: flex !important;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
}
.tr > div {
  width: 12.5%;
  padding: 5px 12px;
}
</style>
